import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  Autocomplete,
  CircularProgress,
  TextField,
  debounce,
} from '@mui/material'
import { useFormikContext } from 'formik'

import { BULK_PURCHASE_TRANSFERABLE_USERS_QUERY } from 'shared/queries'

import type {
  BulkPurchaseTransferableUsersData,
  BulkPurchaseTransferableUsersVars,
  User,
} from 'shared/queries'

type FormValues = {
  [name: string]: User | null
}

type UserSelectFieldProps = {
  label: string
  name: string
  disabled?: boolean
  exceptUserId?: string
}

const UserSelectField = ({
  label,
  name,
  disabled,
  exceptUserId,
}: UserSelectFieldProps) => {
  const { errors, setFieldValue, touched, values } = useFormikContext<FormValues>()

  const [filter, setFilter] = React.useState('')
  const setFilterDelayed = debounce(setFilter, 1000)

  const { data, loading } =
    useQuery<BulkPurchaseTransferableUsersData, BulkPurchaseTransferableUsersVars>(
      BULK_PURCHASE_TRANSFERABLE_USERS_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: { filter },
      })

  const users = (data?.bulkPurchaseTransferableUsers || [])
    .filter(({ id }) => id !== exceptUserId)

  return (
    <Autocomplete
      disabled={disabled}
      loading={loading}
      options={users}
      value={values[name]}
      filterOptions={(x) => x}
      getOptionLabel={(option) => `ID: ${option.id} / ${option.nickName}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_event, newValue) => setFieldValue(name, newValue)}
      onInputChange={(_event, newValue, reason) => {
        if (reason !== 'reset') {
          setFilterDelayed(newValue)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={label}
          placeholder='Filtrar por nombre, email o ID de usuario'
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && (
                  <CircularProgress
                    color='inherit'
                    size={20}
                  />
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default UserSelectField
