import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { SupportView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Support = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Soporte' />
    <SupportView />
  </React.Fragment>
)

export default Support
