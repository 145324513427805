import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { NotFoundView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFound = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders
      title='No encontrado'
      description='No encontrado'
    />
    <NotFoundView />
  </React.Fragment>
)

export default NotFound
