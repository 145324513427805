import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Alert, Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas'

import {
  AppContainer,
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  GridDivider,
  Image,
  Loading,
} from '../components'
import {
  ELECTRONIC_SIGNATURE_QUERY,
  UPDATE_ELECTRONIC_SIGNATURE_MUTATION,
} from '../queries'
import { translateError } from '../services'

import type {
  ElectronicSignatureData,
  ElectronicSignatureVars,
  UpdateElectronicSignatureData,
  UpdateElectronicSignatureVars,
} from '../queries'
import type SignatureCanvasType from 'react-signature-canvas'

const BORDER_STYLE = '1px dotted grey'
const CANVAS_HEIGHT = 150
const CANVAS_WIDTH = 300

type SignatureCreatorProps = {
  setShowCreator: (value: boolean) => void
}

const SignatureCreator = ({
  setShowCreator,
}: SignatureCreatorProps) => {
  const sigCanvas = React.useRef<SignatureCanvasType>(null)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [isEmpty, setIsEmpty] = React.useState(true)

  const [updateElectronicSignature] =
    useMutation<UpdateElectronicSignatureData, UpdateElectronicSignatureVars>(
      UPDATE_ELECTRONIC_SIGNATURE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          ELECTRONIC_SIGNATURE_QUERY,
        ],
      })

  const clear = () => {
    sigCanvas.current?.clear()
    setIsEmpty(true)
  }

  const save = async() => {
    if (!sigCanvas.current || isEmpty) {
      return
    }

    const response = await updateElectronicSignature({
      variables: {
        imageUrl: sigCanvas.current.toDataURL('image/png'),
      },
    })

    if (response.data?.updateElectronicSignature === 'OK!') {
      setShowCreator(false)
      return
    }

    setErrorMsg(translateError(response))
  }

  return (
    <React.Fragment>
      <SignatureCanvas
        ref={sigCanvas}
        penColor='blue'
        canvasProps={{
          height: CANVAS_HEIGHT,
          width: CANVAS_WIDTH,
          style: {
            border: BORDER_STYLE,
          },
        }}
        onBegin={() => setIsEmpty(false)}
      />
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isEmpty}
            onClick={clear}
            variant='outlined'
            color='primary'
          >
            Limpiar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isEmpty}
            onClick={save}
            variant='contained'
            color='primary'
          >
            Guardar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </React.Fragment>
  )
}

type SignatureDisplayProps = {
  imageUrl?: string
  loading: boolean
  setShowCreator: (value: boolean) => void
}

const SignatureDisplay = ({
  imageUrl,
  loading,
  setShowCreator,
}: SignatureDisplayProps) => (
  <Stack
    spacing={3}
    justifyContent='center'
  >
    {(loading) ? (
      <Loading height={`${CANVAS_HEIGHT}px`} />
    ) : (
      (imageUrl) ? (
        <Image
          src={imageUrl}
          alt='Mi firma electrónica'
          duration={300}
          style={{
            border: BORDER_STYLE,
            height: CANVAS_HEIGHT,
            maxWidth: CANVAS_WIDTH,
          }}
        />
      ) : (
        <Alert severity='warning'>
          Tu cuenta aún no posee una firma electrónica
        </Alert>
      )
    )}
    <Button
      disabled={loading}
      onClick={() => setShowCreator(true)}
      variant='contained'
      color='primary'
    >
      {imageUrl ? 'Modificar' : 'Establecer'}
    </Button>
  </Stack>
)

export const SignatureView = () => {
  const [showCreator, setShowCreator] = React.useState(false)

  const { loading, data } =
    useQuery<ElectronicSignatureData, ElectronicSignatureVars>(
      ELECTRONIC_SIGNATURE_QUERY, {
        notifyOnNetworkStatusChange: true,
      })

  return (
    <Container
      disableGutters
      maxWidth='md'
      sx={{ my: 3 }}
    >
      <Grid
        container
        spacing={2}
        justifyContent='center'
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='h4'
            textAlign='center'
          >
            Configuración de firma&nbsp;electrónica
          </Typography>
        </Grid>
        <GridDivider>
          {(data?.electronicSignature?.id) ? (
            (showCreator) ? 'Modificar firma electrónica' : 'Firma electrónica actual'
          ) : (
            'Establecer firma electrónica'
          )}
        </GridDivider>
        <AppContainer
          sx={{ p: 3 }}
          sm={6}
        >
          <Box sx={{ maxWidth: CANVAS_WIDTH, mx: 'auto' }}>
            {(showCreator) ? (
              <SignatureCreator
                setShowCreator={setShowCreator}
              />
            ) : (
              <SignatureDisplay
                imageUrl={data?.electronicSignature?.imageUrl}
                loading={loading}
                setShowCreator={setShowCreator}
              />
            )}
          </Box>
        </AppContainer>
      </Grid>
    </Container>
  )
}
