import * as React from 'react'

import { AmountAftermarketStep } from './amount_aftermarket_step'
import { AmountStep } from './amount_step'
import { ConfirmationStep } from './confirmation_step'

import type { BulkPurchaseConfig } from 'shared/queries'

type BulkPurchaseCreatorProps = {
  config: BulkPurchaseConfig
}

const BulkPurchaseCreator = ({
  config,
}: BulkPurchaseCreatorProps) => {
  const [step, setStep] = React.useState(1)
  const [amount, setAmount] = React.useState<number>(0)
  const [quotePrice, setQuotePrice] = React.useState<number>(0)

  const handleNext = () => setStep((step) => step + 1)

  const handleBack = () => setStep((step) => step - 1)

  switch (step) {
  case 1: return (
    config.isBusinessHours ? (
      <AmountStep
        config={config}
        handleNext={(amount: number, quotePrice: number) => {
          setAmount(amount)
          setQuotePrice(quotePrice)
          handleNext()
        }}
      />
    ) : (
      <AmountAftermarketStep
        config={config}
        handleNext={(amount: number, quotePrice: number) => {
          setAmount(amount)
          setQuotePrice(quotePrice)
          handleNext()
        }}
      />
    )
  )
  case 2: return (
    <ConfirmationStep
      quotePrice={quotePrice}
      amount={amount}
      handleBack={handleBack}
    />
  )
  }
}

export default BulkPurchaseCreator
