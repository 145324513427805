import * as React from 'react'

import { Button, Collapse, FormControl, MenuItem, Stack, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, ErrorDisplay, MonospaceText } from 'shared/components'
import { SelectField } from 'shared/forms'
import { ALLOWED_BLOCKCHAINS } from 'shared/queries'

import type { FormikProps } from 'formik'
import type { AllowedBlockchain, WithdrawalAddress } from 'shared/queries'

const findAddress = (allowedAddresses: WithdrawalAddress[], blockchain: string) => (
  allowedAddresses.find((address) => address.blockchain === blockchain)
)

type FormValues = {
  blockchain: string
}

const initialValues = (withdrawalAddress?: WithdrawalAddress): FormValues => ({
  blockchain: withdrawalAddress?.blockchain || '',
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    blockchain: Yup.string()
      .oneOf(Object.keys(ALLOWED_BLOCKCHAINS))
      .required('Debes seleccionar una dirección de retiro'),
  })

type InnerFormProps = FormikProps<FormValues> & {
  allowedAddresses: WithdrawalAddress[]
  handleBack: () => void
}

const InnerForm = ({
  errors,
  isSubmitting,
  isValid,
  submitForm,
  values,
  allowedAddresses,
  handleBack,
}: InnerFormProps) => {
  const selectedAddress = findAddress(allowedAddresses, values.blockchain)

  return (
    <Form>
      <Stack spacing={3}>
        <FormControl fullWidth>
          <SelectField
            name='blockchain'
            label='Selecciona un blockchain'
          >
            {allowedAddresses.map((withdrawalAddress) => (
              <MenuItem
                key={withdrawalAddress.id}
                value={withdrawalAddress.blockchain}
              >
                {ALLOWED_BLOCKCHAINS[withdrawalAddress.blockchain as AllowedBlockchain]}
              </MenuItem>
            ))}
          </SelectField>
        </FormControl>
        <Collapse in={!!selectedAddress}>
          <MonospaceText textAlign='center'>
            {selectedAddress?.value}
          </MonospaceText>
        </Collapse>
      </Stack>
      <ErrorDisplay
        errorMsg={errors.blockchain}
        mt={3}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            onClick={handleBack}
            variant='outlined'
          >
            Regresar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
          >
            Continuar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type AddressStepProps = {
  allowedAddresses: WithdrawalAddress[]
  withdrawalAddress?: WithdrawalAddress
  handleBack: () => void
  handleNext: (withdrawalAddress: WithdrawalAddress) => void
}

export const AddressStep = ({
  allowedAddresses,
  withdrawalAddress,
  handleBack,
  handleNext,
}: AddressStepProps) => {
  const handleSubmit = (values: FormValues) => {
    const selectedAddress = findAddress(allowedAddresses, values.blockchain)

    if (!selectedAddress) {
      return
    }

    handleNext(selectedAddress)
  }

  return (
    <React.Fragment>
      <Typography
        variant='h6'
        component='span'
        textAlign='center'
        pb={2}
      >
        Dirección de retiro
      </Typography>
      <Formik
        initialValues={initialValues(withdrawalAddress)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            allowedAddresses={allowedAddresses}
            handleBack={handleBack}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}
