import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Add, Delete } from '@mui/icons-material'
import {
  Container,
  Fab,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'

import {
  AppContainer,
  BankAccountCreator,
  Dialog,
  ErrorDisplay,
  GridDivider,
} from '../components'
import {
  BANK_ACCOUNTS_QUERY,
  BANK_ACCOUNT_TYPE_LABELS,
  DELETE_BANK_ACCOUNT_MUTATION,
} from '../queries'
import { BANKS, translateError } from '../services'

import type {
  BankAccount,
  BankAccountsData,
  BankAccountsVars,
  DeleteBankAccountData,
  DeleteBankAccountVars,
} from '../queries'

const LoadingBankAccountDisplay = () => (
  (
    <AppContainer
      md={6}
      sx={{ height: '100%', p: 2 }}
    >
      <Typography variant='h6'>
        <Skeleton width='50%' />
      </Typography>
      <Typography>
        <Skeleton width='70%' />
      </Typography>
    </AppContainer>
  )
)

type BankAccountDisplayProps = {
  bankAccount: BankAccount
}

const BankAccountDisplay = ({
  bankAccount,
}: BankAccountDisplayProps ) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [deleteBankAccount] =
    useMutation<DeleteBankAccountData, DeleteBankAccountVars>(
      DELETE_BANK_ACCOUNT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [BANK_ACCOUNTS_QUERY],
      })

  const handleDelete = async () => {
    const response = await deleteBankAccount({
      variables: {
        bankAccountId: bankAccount.id,
      },
    })

    if (response.data?.deleteBankAccount === 'OK!') {
      return
    }

    setErrorMsg(translateError(response))
  }

  return (
    <AppContainer
      md={6}
      sx={{ height: '100%', p: 2 }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack>
          <Typography variant='h6'>
            {BANKS[bankAccount.bankCode].label}
          </Typography>
          <Typography>
            {BANK_ACCOUNT_TYPE_LABELS[bankAccount.accountType]}: {bankAccount.number}
          </Typography>
        </Stack>
        <IconButton onClick={handleDelete}>
          <Delete />
        </IconButton>
      </Stack>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
    </AppContainer>
  )
}

const NoBankAccountsMessage = () => (
  <AppContainer
    sx={{ p: 3 }}
    sm={6}
  >
    <Typography
      variant='h5'
      textAlign='center'
      fontWeight={500}
    >
      No has asociado una cuenta bancaria
    </Typography>
    <Typography
      textAlign='center'
      pt={2}
    >
      Presiona el botón en la esquina inferior derecha para agregar
      una cuenta bancaria a tu nombre.
    </Typography>
  </AppContainer>
)

const BankAccountCreatorDialog = () => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Agregar cuenta bancaria'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='sm'
        title='Agregar cuenta bancaria'
      >
        <BankAccountCreator
          handleBack={closeDialog}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}

export const BankAccountsView = () => {
  const { loading, data } = useQuery<BankAccountsData, BankAccountsVars>(BANK_ACCOUNTS_QUERY)

  const bankAccounts = data?.bankAccounts || []

  return (
    <Container
      disableGutters
      maxWidth='lg'
      sx={{ my: 3 }}
    >
      <Grid
        container
        spacing={2}
        justifyContent='center'
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='h4'
            textAlign='center'
          >
            Configuración de cuentas&nbsp;bancarias
          </Typography>
        </Grid>
        <GridDivider />
        {(loading) ? (
          [...Array(3)].map((_e, i) => (
            <LoadingBankAccountDisplay key={i} />
          ))
        ) : (
          (bankAccounts.length > 0) ? (
            bankAccounts.map((bankAccount) => (
              <BankAccountDisplay
                key={bankAccount.id}
                bankAccount={bankAccount}
              />
            ))
          ) : (
            <NoBankAccountsMessage />
          )
        )}
        <BankAccountCreatorDialog />
      </Grid>
    </Container>
  )
}
