import * as React from 'react'

import { useMutation } from '@apollo/client'
import {
  Button,
  Divider,
  InputAdornment,
  Paper,
  Stack,
  Typography,
  darken,
  lighten,
  styled,
  useMediaQuery,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import {
  BULK_PURCHASE_CONFIG_QUERY,
  CREATE_BULK_PURCHASE_AFTERMARKET_MUTATION,
  USER_OPERATIONS_QUERY,
} from 'shared/queries'
import { setFormError, translateError } from 'shared/services'

import { LargeAmountDisplay, formatCurrency } from './amount_step'
import { ConfigAftermarketDisclaimer } from './disclaimers'

import type { Theme } from '@mui/material'
import type { FormikProps } from 'formik'
import type {
  BulkPurchaseConfig,
  CreateBulkPurchaseAftermarketData,
  CreateBulkPurchaseAftermarketVars,
} from 'shared/queries'

const Rainbow = styled('span')({
  '@keyframes rainbow': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  animation: 'rainbow 4s ease infinite',
  backgroundSize: '800% 800%',
  backgroundImage: 'repeating-linear-gradient(45deg, violet, indigo, blue, green, yellow, orange, red)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  fontSize: '0.8em',
})

type FormValues = {
  inAmount: number
}

const initialValues: FormValues = ({
  inAmount: 0,
})

const validationSchema = (config: BulkPurchaseConfig): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    inAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales')
      .min(config.minAmount,
        `Debes ingresar un monto mayor a ${formatCurrency(config.minAmount)}`,
      )
      .max(config.maxAmount,
        `Debes ingresar un monto menor a ${formatCurrency(config.maxAmount)}`,
      )
      .max(config.aftermarketRemainingAmount,
        `Debes ingresar un monto menor a ${formatCurrency(config.aftermarketRemainingAmount)}`,
      ),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  config: BulkPurchaseConfig
  quotePrice: number
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  values,
  config,
  quotePrice,
}: InnerFormProps) => {
  const isOnLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <Form>
      <Stack spacing={3}>
        <Typography
          variant='h6'
          textAlign='center'
        >
          Crear orden de compra <Rainbow>AFTERMARKET</Rainbow>
        </Typography>
        <ConfigAftermarketDisclaimer config={config} />
        <CurrencyField
          name='inAmount'
          label='Monto a comprar'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>CLP</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          digits={0}
          positive
        />
        <Paper
          variant='outlined'
          sx={(theme) => ({
            p: 2,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: lighten(theme.palette.info.light, 0.8),
            borderColor: darken(theme.palette.info.light, 0.6),
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
        >
          <LargeAmountDisplay
            currency='CLP'
            value={quotePrice}
            text='Precio aftermarket'
          />
          <Divider
            flexItem
            orientation={isOnLargeScreen ? 'vertical' : 'horizontal'}
          />
          <LargeAmountDisplay
            currency='USDT'
            value={values.inAmount ? (values.inAmount / quotePrice) : 0}
            text='Monto a recibir'
          />
        </Paper>
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='warning'
          >
            COMPRAR
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type AmountAftermarketStepProps = {
  config: BulkPurchaseConfig
  handleNext: (amount: number, quotePrice: number) => void
}

export const AmountAftermarketStep = ({
  config,
  handleNext,
}: AmountAftermarketStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createBulkPurchase] =
    useMutation<CreateBulkPurchaseAftermarketData, CreateBulkPurchaseAftermarketVars>(
      CREATE_BULK_PURCHASE_AFTERMARKET_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [USER_OPERATIONS_QUERY, BULK_PURCHASE_CONFIG_QUERY],
      })

  const handleSubmit = async ({ inAmount }: FormValues) => {
    const response = await createBulkPurchase({
      variables: {
        inAmount,
      },
    })

    if (response.data?.createBulkPurchaseAftermarket === 'OK!') {
      handleNext(inAmount, config.aftermarketPrice)
    } else {
      setFormError(formRef, translateError(response))
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(config)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            config={config}
            quotePrice={config.aftermarketPrice}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
