import * as React from 'react'

import { Grid, Stack, Typography } from '@mui/material'

import { AppContainer, SupportMailLink } from '../components'

export const SupportView = () => (
  <Grid
    container
    maxWidth='sm'
    mx='auto'
  >
    <AppContainer sx={{ px: 3, py: 5 }}>
      <Stack
        spacing={3}
        alignItems='center'
      >
        <Typography
          variant='h4'
          textAlign='center'
        >
          ¿Necesitas ayuda?
        </Typography>
        <Typography
          variant='subtitle1'
          textAlign='center'
        >
          Escríbenos y nos pondremos en contacto.
        </Typography>
        <SupportMailLink />
      </Stack>
    </AppContainer>
  </Grid>
)
