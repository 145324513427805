import * as React from 'react'

import { useQuery } from '@apollo/client'
import { HourglassTop, Settings } from '@mui/icons-material'
import { Alert, Button, Stack, Typography, darken, lighten, useTheme } from '@mui/material'
import { Link } from '@reach/router'

import { IconCard, Loading } from 'shared/components'
import { ALLOWED_BLOCKCHAINS, PLATFORM_STATUS_QUERY } from 'shared/queries'

import { AddressStep } from './address_step'
import { AmountStep } from './amount_step'
import { AuthCodeStep } from './auth_code_step'
import { ConfirmationStep } from './confirmation_step'

import type {
  MarketAsset,
  PlatformStatusData,
  PlatformStatusVars,
  WithdrawalAddress,
} from 'shared/queries'

const DisabledWithdrawalsDisclaimer = ({ dateStr }: { dateStr: string }) => {
  const theme = useTheme()
  const parsedDate = new Date(dateStr)
  const weekdayFmtOpts: Intl.DateTimeFormatOptions = {
    weekday: 'long',
  }
  const dateFmtOpts: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
  }
  const timeFmtOpts: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
  }

  return (
    <Stack spacing={3}>
      <Typography
        variant='h6'
        textAlign='center'
      >
        Retirar al blockchain
      </Typography>
      <IconCard
        elevation={0}
        icon={<HourglassTop />}
        color={darken(theme.palette.info.light, 0.6)}
        bgcolor={lighten(theme.palette.info.light, 0.8)}
        sx={{ border: 1, borderColor: 'info.dark' }}
      >
        Estamos recargando liquidez, los retiros se reanudarán
        {` el ${parsedDate.toLocaleString([], weekdayFmtOpts)} `}
        {parsedDate.toLocaleString([], dateFmtOpts)}
        {parsedDate.getHours() === 1 ? ' a la ' : ' a las '}
        {parsedDate.toLocaleTimeString([], timeFmtOpts)}
      </IconCard>
    </Stack>
  )
}

export type WithdrawBlockchainFormProps = {
  marketAssets: MarketAsset[]
  withdrawableAmount: number
  withdrawalAddresses: WithdrawalAddress[]
}

export const WithdrawBlockchainForm = ({
  marketAssets,
  withdrawableAmount,
  withdrawalAddresses,
}: WithdrawBlockchainFormProps) => {
  const [step, setStep] = React.useState(1)
  const [amount, setAmount] = React.useState<number>(0)
  const [withdrawalAddress, setWithdrawalAddress] = React.useState<WithdrawalAddress>()

  const { loading, data } =
    useQuery<PlatformStatusData, PlatformStatusVars>(PLATFORM_STATUS_QUERY)

  const withdrawalsDisabledUntil = data?.platformStatus.withdrawalsDisabledUntil

  if (loading) {
    return <Loading />
  } else if (withdrawalsDisabledUntil) {
    return <DisabledWithdrawalsDisclaimer dateStr={withdrawalsDisabledUntil} />
  }

  const handleBack = () => setStep(Math.max(step - 1, 1))

  const handleNext = () => setStep((step) => step + 1)

  const allowedWithdrawalAddresses = withdrawalAddresses
    .filter(({ blockchain }) => Object.keys(ALLOWED_BLOCKCHAINS).includes(blockchain))

  const marketAsset = marketAssets
    .find(({ symbol }) => symbol === 'USDT') || null

  if (allowedWithdrawalAddresses.length === 0) {
    return (
      <Stack spacing={3}>
        <Typography
          variant='h6'
          textAlign='center'
        >
          Retirar al blockchain
        </Typography>
        <Alert severity='warning'>
          Por tu seguridad, necesitamos que establezcas una dirección de retiro
          fija para todos tus retiros.
        </Alert>
        <Button
          component={Link}
          to='/app/withdrawal-address'
          startIcon={<Settings />}
          variant='contained'
          size='large'
          fullWidth
        >
          Configurar dirección de retiro
        </Button>
      </Stack>
    )
  }

  return (
    <Stack
      margin='auto'
      height='100%'
      width='100%'
      maxWidth='420px'
      spacing={2}
      pb={1}
    >
      {(() => {
        switch (step) {
        case 1: return (
          <AmountStep
            withdrawableAmount={withdrawableAmount}
            amount={amount}
            handleNext={(amount: number) => {
              setAmount(amount)
              handleNext()
            }}
          />
        )
        case 2: return (
          <AddressStep
            allowedAddresses={allowedWithdrawalAddresses}
            withdrawalAddress={withdrawalAddress}
            handleBack={handleBack}
            handleNext={(withdrawalAddress: WithdrawalAddress) => {
              setWithdrawalAddress(withdrawalAddress)
              handleNext()
            }}
          />
        )
        case 3: return (
          <AuthCodeStep
            marketAsset={marketAsset}
            blockchain={withdrawalAddress?.blockchain}
            address={withdrawalAddress?.value}
            amount={amount}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )
        case 4: return (
          <ConfirmationStep
            marketAsset={marketAsset}
            blockchain={withdrawalAddress?.blockchain}
            address={withdrawalAddress?.value}
            amount={amount}
          />
        )
        }
      })()}
    </Stack>
  )
}
