import * as React from 'react'

import {
  AttachMoney,
  Description,
  History as HistoryIcon,
  RequestQuote,
  ShowChart,
  Upload,
} from '@mui/icons-material'
import { Link, Redirect, Router, useLocation } from '@reach/router'

import { DrawerDivider, DrawerList, DrawerListItem } from 'shared/components'

import BankAccounts from '../components/views/bank_accounts'
import BulkPurchases from '../components/views/bulk_purchases'
import Documents from '../components/views/documents'
import History from '../components/views/history'
import NotFound from '../components/views/not_found'
import Purchase from '../components/views/purchase'
import Security from '../components/views/security'
import Signature from '../components/views/signature'
import Support from '../components/views/support'
import WithdrawBlockchain from '../components/views/withdraw_blockchain'
import WithdrawalAddress from '../components/views/withdrawal_address'
import Layout from '../layouts/app'

const App = () => {
  const pathname = useLocation().pathname.replace(/\/$/, '')
  const basepath = '/app'

  const isCurrentPath = (href: string): boolean => pathname.startsWith(basepath + '/' + href)

  const listItem = (text: string, href: string, icon: React.ReactNode, color?: string) => (
    <DrawerListItem
      text={text}
      href={href}
      icon={icon}
      color={color}
      selected={isCurrentPath(href)}
      component={Link}
    />
  )

  const drawerContent = (
    <React.Fragment>
      <DrawerList>
        {listItem('Órdenes', 'bulk-purchases', <AttachMoney />)}
        {listItem('Comprar', 'purchase', <RequestQuote />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Historial', 'history', <HistoryIcon />)}
        {listItem('Retirar', 'withdraw/blockchain', <Upload />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Documentos', 'documents', <ShowChart />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Soporte', 'support', <Description />)}
      </DrawerList>
    </React.Fragment>
  )

  return (
    <Router basepath={basepath}>
      <Layout
        path='/'
        drawerContent={drawerContent}
      >
        <Redirect
          noThrow
          from='/'
          to='bulk-purchases'
        />
        <BulkPurchases path='bulk-purchases' />
        <Purchase path='purchase' />
        <Documents path='documents' />
        <History path='history' />
        <BankAccounts path='bank' />
        <Signature path='signature' />
        <Support path='support' />
        <Security path='security' />
        <WithdrawalAddress path='withdrawal-address' />
        <WithdrawBlockchain path='withdraw/blockchain' />
        <NotFound default />
      </Layout>
    </Router>
  )
}

export default App
