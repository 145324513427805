import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { SecurityView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Security = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Configuración de Seguridad' />
    <SecurityView />
  </React.Fragment>
)

export default Security
