import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { WhatsApp } from '@mui/icons-material'
import {
  Button,
  Divider,
  InputAdornment,
  Paper,
  Stack,
  Typography,
  darken,
  lighten,
  useMediaQuery,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  ErrorDisplay,
  Loading,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import {
  BULK_PURCHASE_CONFIG_QUERY,
  BULK_PURCHASE_QUOTE_QUERY,
  CREATE_BULK_PURCHASE_MUTATION,
  USER_OPERATIONS_QUERY,
} from 'shared/queries'
import { numberFormatter, setFormError, translateError } from 'shared/services'

import { ConfigDisclaimer, CreationDisclaimer, ErrorDisclaimer } from './disclaimers'
import { whatsappLink } from '../../services/bulk_purchases'

import type { Theme } from '@mui/material'
import type { FormikProps } from 'formik'
import type {
  BulkPurchaseConfig,
  BulkPurchaseQuoteData,
  BulkPurchaseQuoteVars,
  CreateBulkPurchaseData,
  CreateBulkPurchaseVars,
} from 'shared/queries'

export const formatCurrency = (amount: number) => numberFormatter(0).format(amount)

type LargeAmountDisplayProps = {
  currency: string
  value: number
  text: string
}

export const LargeAmountDisplay = ({
  currency,
  value,
  text,
}: LargeAmountDisplayProps) => (
  <Stack width='50%'>
    <Typography
      variant='h4'
      component='p'
      fontWeight={600}
      lineHeight='1.2'
      textAlign='center'
    >
      <Currency
        currency={currency}
        digits={2}
        value={value}
      />
    </Typography>
    <Typography
      variant='h6'
      component='p'
      textAlign='center'
    >
      {text}
    </Typography>
  </Stack>
)

type FormValues = {
  inAmount: number
}

const initialValues: FormValues = ({
  inAmount: 0,
})

const validationSchema = ({ minAmount }: BulkPurchaseConfig): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    inAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales')
      .min(minAmount, `Debes ingresar un monto mayor a ${formatCurrency(minAmount)}`,
      ),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  config: BulkPurchaseConfig
  quotePrice: number
}

const InnerForm = ({
  isSubmitting,
  status,
  submitForm,
  values,
  config,
  quotePrice,
}: InnerFormProps) => {
  const isOnLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const disablePurchaseButton = (
    values.inAmount > config.maxAmount || values.inAmount > config.dailyRemainingAmount
  )

  return (
    <Form>
      <Stack spacing={3}>
        <Typography
          variant='h6'
          textAlign='center'
        >
          Crear orden de compra
        </Typography>
        <ConfigDisclaimer config={config} />
        <CurrencyField
          name='inAmount'
          label='Monto a comprar'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>CLP</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          digits={0}
          positive
        />
        <Paper
          variant='outlined'
          sx={(theme) => ({
            p: 2,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: lighten(theme.palette.info.light, 0.8),
            borderColor: darken(theme.palette.info.light, 0.6),
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
        >
          <LargeAmountDisplay
            currency='CLP'
            value={quotePrice}
            text='Precio actual'
          />
          <Divider
            flexItem
            orientation={isOnLargeScreen ? 'vertical' : 'horizontal'}
          />
          <LargeAmountDisplay
            currency='USDT'
            value={values.inAmount ? (values.inAmount / quotePrice) : 0}
            text='Monto a recibir'
          />
        </Paper>
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !disablePurchaseButton}
            target='_blank'
            href={whatsappLink(formatCurrency(values.inAmount))}
            rel='noopener noreferrer'
            variant='contained'
            color='warning'
            startIcon={<WhatsApp />}
          >
            Cotizar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || disablePurchaseButton}
            onClick={submitForm}
            variant='contained'
            color='warning'
          >
            COMPRAR
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type AmountStepProps = {
  config: BulkPurchaseConfig
  handleNext: (amount: number, quotePrice: number) => void
}

export const AmountStep = ({
  config,
  handleNext,
}: AmountStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading, data, refetch, error } =
    useQuery<BulkPurchaseQuoteData, BulkPurchaseQuoteVars>(
      BULK_PURCHASE_QUOTE_QUERY, {
        fetchPolicy: 'network-only',
      })

  const quote = data?.bulkPurchaseQuote

  const [createBulkPurchase] =
    useMutation<CreateBulkPurchaseData, CreateBulkPurchaseVars>(
      CREATE_BULK_PURCHASE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [USER_OPERATIONS_QUERY, BULK_PURCHASE_CONFIG_QUERY],
      })

  const handleSubmit = async ({ inAmount }: FormValues) => {
    if (!quote) {
      return
    }

    const response = await createBulkPurchase({
      variables: {
        quoteId: quote.id,
        inAmount,
      },
    })

    if (response.data?.createBulkPurchase === 'OK!') {
      handleNext(inAmount, quote.price)
    } else {
      setFormError(formRef, translateError(response))
    }
  }

  const hasReachedDailyLimit = (config.minAmount > config.dailyRemainingAmount)

  React.useEffect(() => {
    if (hasReachedDailyLimit || error) {
      return
    }

    const bulkPurchaseQuoteLoop = setInterval(() => {
      refetch()
    }, 1000)

    return () => clearInterval(bulkPurchaseQuoteLoop)
  }, [hasReachedDailyLimit, error, refetch])

  if (loading) {
    return <Loading />
  } else if (error) {
    return <ErrorDisclaimer />
  }

  return hasReachedDailyLimit ? (
    <CreationDisclaimer>
      Has alcanzado el límite diario de creación de órdenes desde el dashboard.
    </CreationDisclaimer>
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(config)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            config={config}
            quotePrice={quote?.price || 1}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
