import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Add, Edit, Security, Warning } from '@mui/icons-material'
import {
  Alert,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@mui/material'
import { Link } from '@reach/router'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import {
  AppContainer,
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  GridDivider,
  IconCard,
  Loading,
  MonospaceText,
} from '../components'
import { SelectField } from '../forms'
import {
  ALLOWED_BLOCKCHAINS,
  OTP_PROVISIONING_URI_QUERY,
  UPDATE_WITHDRAWAL_ADDRESS_MUTATION,
  WITHDRAWAL_ADDRESSES_QUERY,
} from '../queries'
import { setFormError, translateError } from '../services'

import type {
  OtpProvisioningUriData,
  OtpProvisioningUriVars,
  UpdateWithdrawalAddressData,
  UpdateWithdrawalAddressVars,
  WithdrawalAddress,
  WithdrawalAddressesData,
  WithdrawalAddressesVars,
} from '../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  blockchain: string
  value: string
  otp: string
}

const initialValues: FormValues = {
  blockchain: '',
  value: '',
  otp: '',
}

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    blockchain: Yup.string()
      .required('Este campo es obligatorio'),
    value: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, 'La dirección solo puede contener letras o números')
      .required('Este campo es obligatorio'),
    otp: Yup.string()
      .length(6, 'El código debe tener 6 dígitos')
      .matches(/^\d+$/, 'El código debe contener sólo números')
      .required('Este campo es obligatorio'),
  })

type InnerFormProps = FormikProps<FormValues> & {
  loading: boolean
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  withdrawalAddresses: WithdrawalAddress[]
}

const InnerForm = ({
  isSubmitting,
  isValid,
  setFieldTouched,
  setFieldValue,
  status,
  submitForm,
  values,
  loading,
  isEditing,
  setIsEditing,
  withdrawalAddresses,
}: InnerFormProps) => {
  const theme = useTheme()

  const withdrawalAddress =
    withdrawalAddresses.find(({ blockchain }) => blockchain === values.blockchain)

  const resetEditor = () => {
    setFieldValue('value', '')
    setFieldTouched('value', false, false)
    setFieldValue('otp', '')
    setFieldTouched('otp', false, false)
  }

  return (
    <Form>
      <SelectField
        name='blockchain'
        label='Seleccionar blockchain'
        disabled={loading}
        onChange={resetEditor}
        fullWidth
      >
        {Object.entries(ALLOWED_BLOCKCHAINS).map(([blockchain, label]) => (
          <MenuItem
            key={blockchain}
            value={blockchain}
          >
            {label}
          </MenuItem>
        ))}
      </SelectField>
      <Collapse
        in={!!values.blockchain}
        sx={{
          pt: values.blockchain ? 3 : 0,
          '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          },
        }}
      >
        {(isEditing) ? (
          <React.Fragment>
            <Divider>
              {(withdrawalAddress)
                ? 'Actualizar dirección de retiro'
                : 'Agregar dirección de retiro'}
            </Divider>
            <IconCard
              elevation={0}
              icon={<Warning />}
              color={darken(theme.palette.warning.light, 0.6)}
              bgcolor={lighten(theme.palette.warning.light, 0.8)}
              sx={{ border: 1, borderColor: 'warning.dark' }}
            >
              La dirección de retiro que configures debe ser una wallet personal bajo tu control.
              <br style={{ display: 'block', content: '""', height: '0.5em' }} />
              Asegúrate de verificar cuidadosamente la dirección y la red antes de guardarla.
              <br style={{ display: 'block', content: '""', height: '0.5em' }} />
              Fugiro no puede recuperar fondos enviados a una dirección incorrecta o a una red incompatible.
              <br style={{ display: 'block', content: '""', height: '0.5em' }} />
              Es exclusivamente de tu responsabilidad que la información ingresada sea correcta.
            </IconCard>
            <Field
              required
              name='value'
              type='text'
              label={withdrawalAddress ? 'Nueva dirección' : 'Dirección'}
              component={TextField}
              margin='dense'
              inputProps={{ autoComplete: 'one-time-code' }}
              fullWidth
            />
            <Field
              required
              name='otp'
              type='text'
              label='Código de autentificación two-factor (2FA)'
              component={TextField}
              margin='dense'
              inputProps={{ autoComplete: 'one-time-code' }}
              fullWidth
            />
            <ErrorDisplay
              errorMsg={status?.errorMsg}
            />
            <ButtonsContainer sx={{ alignItems: 'flex-end' }}>
              <ButtonContainer xs={6}>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  onClick={() => {
                    setIsEditing(false)
                    resetEditor()
                  }}
                  variant='outlined'
                >
                  Cancelar
                </Button>
              </ButtonContainer>
              <ButtonContainer xs={6}>
                <Button
                  fullWidth
                  disabled={isSubmitting || !isValid}
                  onClick={submitForm}
                  variant='contained'
                >
                  {withdrawalAddress ? 'Actualizar' : 'Agregar'}
                </Button>
              </ButtonContainer>
            </ButtonsContainer>
          </React.Fragment>
        ) : (
          (withdrawalAddress) ? (
            <React.Fragment>
              <MonospaceText textAlign='center'>
                {withdrawalAddress.value}
              </MonospaceText>
              <Button
                onClick={() => setIsEditing(true)}
                startIcon={<Edit />}
                variant='outlined'
                color='primary'
                size='large'
                fullWidth
              >
                Actualizar dirección
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Alert severity='info'>
                No se ha configurado una dirección de retiro para este blockchain.
              </Alert>
              <Button
                onClick={() => setIsEditing(true)}
                startIcon={<Add />}
                variant='contained'
                color='primary'
                size='large'
                fullWidth
              >
                Agregar dirección
              </Button>
            </React.Fragment>
          )
        )}
      </Collapse>
    </Form>
  )
}

export const WithdrawalAddressForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [isEditing, setIsEditing] = React.useState(false)

  const { data, loading } =
    useQuery<WithdrawalAddressesData, WithdrawalAddressesVars>(WITHDRAWAL_ADDRESSES_QUERY)

  const [updateWithdrawalAddress] =
    useMutation<UpdateWithdrawalAddressData, UpdateWithdrawalAddressVars>(
      UPDATE_WITHDRAWAL_ADDRESS_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          WITHDRAWAL_ADDRESSES_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateWithdrawalAddress({ variables: values })

    if (response.data?.updateWithdrawalAddress === 'OK!') {
      setIsEditing(false)
      return
    }

    setFormError(formRef, translateError(response))
    formRef.current?.setFieldValue('otp', '')
    formRef.current?.setFieldTouched('otp', false, false)
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <InnerForm
          {...props}
          loading={loading}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          withdrawalAddresses={data?.withdrawalAddresses || []}
        />
      )}
    </Formik>
  )
}

export const WithdrawalAddressView = () => {
  const { data, loading } =
    useQuery<OtpProvisioningUriData, OtpProvisioningUriVars>(OTP_PROVISIONING_URI_QUERY)

  const has2faConfigured = !data?.otpProvisioningUri

  return (
    <Container
      disableGutters
      maxWidth='md'
      sx={{ my: 3 }}
    >
      <Grid
        container
        spacing={2}
        justifyContent='center'
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='h4'
            textAlign='center'
          >
            Direcciones de retiro blockchain
          </Typography>
        </Grid>
        <GridDivider />
        <AppContainer
          sx={{ p: 3 }}
          sm={6}
        >
          {(has2faConfigured) ? (
            <WithdrawalAddressForm />
          ) : (
            (loading) ? (
              <Loading />
            ) : (
              <Stack spacing={3}>
                <Alert severity='warning'>
                  Por tu seguridad, para acceder esta configuración primero
                  debes habilitar la autentificación de dos factores (2FA).
                </Alert>
                <Button
                  component={Link}
                  to='/app/security'
                  startIcon={<Security />}
                  variant='contained'
                  size='large'
                  fullWidth
                >
                  Activar 2FA
                </Button>
              </Stack>
            )
          )}
        </AppContainer>
      </Grid>
    </Container>
  )
}
