import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Autocomplete, Box, Grid } from '@mui/material'

import {
  AssetBadge,
  AutocompleteContainer,
  OperationsList,
  OptionCheckbox,
  OptionDisplay,
  OptionTextField,
} from '../components'
import {
  MARKET_ASSETS_QUERY,
  USER_OPERATIONS_QUERY,
  USER_OPERATION_LABELS,
} from '../queries'
import { asOperationsArray, withSymbols, withTypes } from '../services'

import type {
  MarketAssetsData,
  MarketAssetsVars,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

type FilteredOperationsListProps = {
  symbols: string[]
  operationTypes: string[]
}

const FilteredOperationsList = ({
  symbols,
  operationTypes,
}: FilteredOperationsListProps) => {
  const { loading, data } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  let assetOperations = asOperationsArray(data?.userOperations)

  if (symbols.length) {
    assetOperations = withSymbols(assetOperations, symbols)
  }

  if (operationTypes.length) {
    assetOperations = withTypes(assetOperations, operationTypes)
  }

  return (
    <OperationsList
      loading={loading}
      operations={assetOperations}
    />
  )
}

export const HistoryView = () => {
  const [symbols, setSymbols] = React.useState<string[]>([])
  const [operationTypes, setOperationTypes] = React.useState<string[]>([])

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = assetsData?.marketAssets || []

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
      <Grid
        container
        spacing={3}
      >
        <AutocompleteContainer>
          <Autocomplete
            disableCloseOnSelect
            multiple
            limitTags={2}
            disabled={assetsLoading}
            options={marketAssets}
            getOptionLabel={(option) => option.name}
            onChange={(_event, newValue) => {
              setSymbols(newValue.map((asset) => asset.symbol))
            }}
            renderOption={(props, option, { selected }) => (
              <OptionDisplay {...props}>
                <OptionCheckbox checked={selected} />
                <AssetBadge
                  symbol={option.symbol}
                  height={20}
                />
                {option.name}
              </OptionDisplay>
            )}
            renderInput={(params) => (
              <OptionTextField
                name='marketAsset'
                label='Filtrar por activo'
                params={params}
              />
            )}
            disablePortal
          />
        </AutocompleteContainer>
        <AutocompleteContainer>
          <Autocomplete
            disableCloseOnSelect
            multiple
            limitTags={2}
            options={USER_OPERATION_LABELS}
            getOptionLabel={(option) => option.label}
            onChange={(_event, newValue) => {
              setOperationTypes(newValue.map((operationLabel) => operationLabel.typename))
            }}
            renderOption={(props, option, { selected }) => (
              <OptionDisplay {...props}>
                <OptionCheckbox checked={selected} />
                {option.label}
              </OptionDisplay>
            )}
            renderInput={(params) => (
              <OptionTextField
                name='operationType'
                label='Filtrar por tipo de operación'
                params={params}
              />
            )}
            disablePortal
          />
        </AutocompleteContainer>
        <FilteredOperationsList
          symbols={symbols}
          operationTypes={operationTypes}
        />
      </Grid>
    </Box>
  )
}
