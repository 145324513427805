import * as React from 'react'

import { AttachMoney, Check, RequestQuote } from '@mui/icons-material'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@mui/material'
import { Link } from '@reach/router'

import { ButtonContainer, ButtonsContainer, Currency, IconCard } from 'shared/components'

type ConfirmationStepProps = {
  quotePrice: number
  amount: number
  handleBack: () => void
}

export const ConfirmationStep = ({
  quotePrice,
  amount,
  handleBack,
}: ConfirmationStepProps) => {
  const theme = useTheme()
  return (
    <React.Fragment>
      <Stack spacing={3}>
        <Typography
          variant='h6'
          textAlign='center'
        >
          Orden creada
        </Typography>
        <List disablePadding>
          <ListItem
            divider
            disableGutters
          >
            <ListItemText primary='Monto comprado' />
            <ListItemText
              primary={(
                <Currency
                  currency='CLP'
                  value={amount}
                  digits={0}
                />
              )}
              primaryTypographyProps={{ textAlign: 'right' }}
            />
          </ListItem>
          <ListItem
            divider
            disableGutters
          >
            <ListItemText primary='Precio de compra' />
            <ListItemText
              primary={(
                <Currency
                  currency='CLP'
                  value={quotePrice}
                />
              )}
              primaryTypographyProps={{ textAlign: 'right' }}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary='Monto a recibir' />
            <ListItemText
              primary={(
                <Currency
                  currency='USDT'
                  value={amount / quotePrice}
                  digits={2}
                />
              )}
              primaryTypographyProps={{ textAlign: 'right' }}
            />
          </ListItem>
        </List>
        <IconCard
          elevation={0}
          icon={<Check />}
          color={darken(theme.palette.success.light, 0.6)}
          bgcolor={lighten(theme.palette.success.light, 0.8)}
          sx={{ border: 1, borderColor: 'success.main' }}
        >
          Puedes hacer seguimiento a tus órdenes en las vistas
          de <strong>Órdenes</strong> e <strong>Historial</strong>
        </IconCard>
      </Stack>
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            component={Link}
            to='../bulk-purchases'
            startIcon={<AttachMoney />}
            variant='outlined'
          >
            Ir a Órdenes
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            onClick={handleBack}
            startIcon={<RequestQuote />}
            variant='contained'
          >
            Comprar más
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </React.Fragment>
  )
}
