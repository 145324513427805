import * as React from 'react'

import { useQuery } from '@apollo/client'

import { BulkPurchasesDashboard, SeoHeaders } from 'shared/components'
import {
  ACCOUNT_INFORMATION_QUERY,
  BULK_PURCHASES_OVERVIEW_QUERY,
  BULK_PURCHASE_TRANSFERABLE_USERS_QUERY,
} from 'shared/queries'
import { withJsTimestamp } from 'shared/services'

import BulkPurchaseTransferrer from '../bulk_purchase_transferrer'

import type { RouteComponentProps } from '@reach/router'
import type {
  AccountInformationData,
  AccountInformationVars,
  BulkPurchaseTransferableUsersData,
  BulkPurchaseTransferableUsersVars,
  BulkPurchasesOverviewData,
  BulkPurchasesOverviewVars,
} from 'shared/queries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BulkPurchases = (props: RouteComponentProps) => {
  const { loading: overviewLoading, data: overviewData } =
    useQuery<BulkPurchasesOverviewData, BulkPurchasesOverviewVars>(BULK_PURCHASES_OVERVIEW_QUERY)

  const { loading: accountLoading, data: accountData } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  const { data: transferableUsersData } =
    useQuery<BulkPurchaseTransferableUsersData, BulkPurchaseTransferableUsersVars>(
      BULK_PURCHASE_TRANSFERABLE_USERS_QUERY,
    )

  const transferableUsersCount = transferableUsersData?.bulkPurchaseTransferableUsers.length || 0

  const isVerified = accountData?.accountInformation.accountStatus === 'IDENTITY_VERIFIED'

  const bulkPurchasesOverview = overviewData?.bulkPurchasesOverview
  const openBulkPurchases = withJsTimestamp(bulkPurchasesOverview?.openBulkPurchases || [])

  return (
    <React.Fragment>
      <SeoHeaders title='Órdenes' />
      <BulkPurchasesDashboard
        loading={overviewLoading || accountLoading}
        bulkPurchasesOverview={bulkPurchasesOverview}
        withBankTransferDialog={isVerified}
        withBankDepositSimulator
      />
      {(transferableUsersCount > 0) && <BulkPurchaseTransferrer bulkPurchases={openBulkPurchases} />}
    </React.Fragment>
  )
}

export default BulkPurchases
