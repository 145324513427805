import * as React from 'react'

import { Box } from '@mui/material'

import {
  AppNavElements,
  BannersDisplay,
  ResponsiveDrawer,
} from 'shared/components'

import NavBar from '../components/nav_bar'

import type { RouteComponentProps } from '@reach/router'

const drawerWidth = 220

type LayoutProps = RouteComponentProps & {
  drawerContent: React.ReactNode
  children: React.ReactNode
}

export const Layout = ({ drawerContent, children }: LayoutProps) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false)

  const toggleMobileDrawerOpen = () => setMobileDrawerOpen(!mobileDrawerOpen)

  const onMobileDrawerClose = () => setMobileDrawerOpen(false)

  return (
    <React.Fragment>
      <NavBar
        onMenuButtonClick={toggleMobileDrawerOpen}
        rootPath='/app/'
      >
        <AppNavElements configurationPages={['signature', 'bank', 'withdrawal-address']} />
      </NavBar>
      <ResponsiveDrawer
        width={drawerWidth}
        mobileOpen={mobileDrawerOpen}
        mobileOnClose={onMobileDrawerClose}
      >
        {drawerContent}
      </ResponsiveDrawer>
      <Box
        sx={(theme) => ({
          p: 3,
          width: '100%',
          bgcolor: 'action.hover',
          minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
          [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          },
        })}
      >
        <BannersDisplay />
        {children}
      </Box>
    </React.Fragment>
  )
}

export default Layout
