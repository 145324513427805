import * as React from 'react'

import {
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import { isValidAssetAmount } from 'shared/services'

import type { FormikProps } from 'formik'

type FormValues = {
  amount: number
}

const initialValues = (amount: number): FormValues => ({
  amount: amount,
})

const validationSchema = (totalAmount: number): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .max(totalAmount, 'Debes ingresar un monto menor a tu balance')
      .test('validFormat', 'Introduce un número con máximo 6 decimales', isValidAssetAmount),
  })

const InnerForm = ({
  isSubmitting,
  isValid,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form>
    <CurrencyField
      required
      name='amount'
      label='Monto a retirar'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <small>USDT</small>
          </InputAdornment>
        ),
      }}
      fullWidth
      digits={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type AmountStepProps = {
  withdrawableAmount: number
  amount: number
  handleNext: (amount: number) => void
}

export const AmountStep = ({
  withdrawableAmount,
  amount,
  handleNext,
}: AmountStepProps) => (
  <React.Fragment>
    <Typography
      variant='h6'
      textAlign='center'
    >
      Retirar al blockchain
    </Typography>
    <List disablePadding>
      <ListItem disableGutters>
        <ListItemText primary='Disponible para retiro' />
        <ListItemText
          primary={(
            <Currency
              currency='USDT'
              digits={2}
              value={withdrawableAmount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
    </List>
    <Formik
      initialValues={initialValues(amount)}
      validationSchema={validationSchema(withdrawableAmount)}
      onSubmit={(values) => handleNext(values.amount)}
    >
      {(props) => <InnerForm {...props} />}
    </Formik>
  </React.Fragment>
)
