import * as React from 'react'

import { Typography } from '@mui/material'

export const NotFoundView = () => (
  <Typography
    variant='body1'
    color='text.secondary'
    textAlign='center'
  >
    ...acá no hay nada (aún) 😶‍🌫️
  </Typography>
)
