import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import { AppContainer, Loading, SeoHeaders } from 'shared/components'
import {
  BULK_PURCHASES_OVERVIEW_QUERY,
  MARKET_ASSETS_QUERY,
  WITHDRAWAL_ADDRESSES_QUERY,
} from 'shared/queries'

import { WithdrawBlockchainForm } from '../withdraw_blockchain_form'

import type { RouteComponentProps } from '@reach/router'
import type {
  BulkPurchasesOverviewData,
  BulkPurchasesOverviewVars,
  MarketAssetsData,
  MarketAssetsVars,
  WithdrawalAddressesData,
  WithdrawalAddressesVars,
} from 'shared/queries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WithdrawBlockchainView = (props: RouteComponentProps) => {
  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: overviewLoading, data: overviewData } =
    useQuery<BulkPurchasesOverviewData, BulkPurchasesOverviewVars>(BULK_PURCHASES_OVERVIEW_QUERY)

  const { loading: withdrawalAddressesLoading, data: withdrawalAddressesData } =
    useQuery<WithdrawalAddressesData, WithdrawalAddressesVars>(WITHDRAWAL_ADDRESSES_QUERY)

  const marketAssets = assetsData?.marketAssets || []
  const withdrawableAmount = overviewData?.bulkPurchasesOverview.withdrawableAmount || 0
  const withdrawalAddresses = withdrawalAddressesData?.withdrawalAddresses || []

  return (
    <React.Fragment>
      <SeoHeaders title='Retirar al blockchain' />
      <Grid
        container
        maxWidth='sm'
        mx='auto'
      >
        <AppContainer sx={{ p: 3 }}>
          {(assetsLoading || overviewLoading || withdrawalAddressesLoading) ? (
            <Loading />
          ) : (
            <WithdrawBlockchainForm
              marketAssets={marketAssets}
              withdrawableAmount={withdrawableAmount}
              withdrawalAddresses={withdrawalAddresses}
            />
          )}
        </AppContainer>
      </Grid>
    </React.Fragment>
  )
}

export default WithdrawBlockchainView
