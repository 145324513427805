import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { WithdrawalAddressView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WithdrawalAddress = (props: RouteComponentProps) => {
  return (
    <React.Fragment>
      <SeoHeaders title='Direcciones de retiro blockchain' />
      <WithdrawalAddressView />
    </React.Fragment>
  )
}

export default WithdrawalAddress
