import * as React from 'react'

import { Container, Divider, Typography } from '@mui/material'

import { OtpSettings } from '../components'

export const SecurityView = () => (
  <Container
    maxWidth='md'
    sx={{
      mx: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 2,
      px: 2,
      '& > .MuiDivider-root': {
        width: '100%',
        my: 1,
      },
    }}
  >
    <Typography
      variant='h4'
      textAlign='center'
    >
      Configuración de Seguridad
    </Typography>
    <Divider>Habilitar 2FA</Divider>
    <OtpSettings />
  </Container>
)
