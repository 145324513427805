import * as React from 'react'

import { AttachMoney, History } from '@mui/icons-material'
import {
  Alert,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Link } from '@reach/router'

import { ButtonContainer, ButtonsContainer, Currency } from 'shared/components'

import type { MarketAsset } from 'shared/queries'

type ConfirmationStepProps = {
  marketAsset: MarketAsset | null
  blockchain?: string
  address?: string
  amount: number
}

export const ConfirmationStep = ({
  marketAsset,
  blockchain,
  address,
  amount,
}: ConfirmationStepProps) => (
  <React.Fragment>
    <List disablePadding>
      <ListItem
        disableGutters
        sx={{ pt: 0, mb: 1 }}
      >
        <ListItemText
          primary='Retiro completado'
          primaryTypographyProps={{ variant: 'h6', textAlign: 'center' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Cantidad' />
        <ListItemText
          primary={(
            <Currency
              currency={marketAsset?.symbol || '-'}
              value={amount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Comisión de red' />
        <ListItemText
          primary='Incluida'
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Red' />
        <ListItemText
          primary={blockchain}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem disableGutters>
        <ListItemText primary='Dirección' />
        <ListItemText
          primary={address}
          primaryTypographyProps={{ fontFamily: 'monospace', fontSize: '.95em', textAlign: 'right' }}
          sx={{ overflowWrap: 'anywhere', maxWidth: '60%' }}
        />
      </ListItem>
    </List>
    <Typography
      variant='h6'
      component='p'
      textAlign='center'
      sx={{ pt: 2 }}
    >
      Transacción iniciada
    </Typography>
    <Alert severity='success'>
      Puedes ver el estado de esta transacción en Historial.
    </Alert>
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../../history'
          startIcon={<History />}
          variant='outlined'
        >
          Ir a Historial
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../../bulk-purchases'
          startIcon={<AttachMoney />}
          variant='contained'
        >
          Ir a Órdenes
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </React.Fragment>
)
